<template>
    <div class="icon-eye">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path d="M2.92012 13.2132C2.78394 12.9975 2.71584 12.8897 2.67772 12.7234C2.64909 12.5985 2.64909 12.4015 2.67772 12.2766C2.71584 12.1103 2.78394 12.0025 2.92012 11.7868C4.04553 10.0048 7.3954 5.5 12.5004 5.5C17.6054 5.5 20.9553 10.0048 22.0807 11.7868C22.2169 12.0025 22.285 12.1103 22.3231 12.2766C22.3517 12.4015 22.3517 12.5985 22.3231 12.7234C22.285 12.8897 22.2169 12.9975 22.0807 13.2132C20.9553 14.9952 17.6054 19.5 12.5004 19.5C7.3954 19.5 4.04553 14.9952 2.92012 13.2132Z" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" />
            <path d="M12.5004 15.5C14.1573 15.5 15.5004 14.1569 15.5004 12.5C15.5004 10.8431 14.1573 9.5 12.5004 9.5C10.8435 9.5 9.5004 10.8431 9.5004 12.5C9.5004 14.1569 10.8435 15.5 12.5004 15.5Z" stroke="white" stroke-width="2" stroke-linecap="square" stroke-linejoin="round" />
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<style scopped lang="scss">
    .icon-eye {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);

        &:hover{
            cursor: pointer;
        }
    }
</style>